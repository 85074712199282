import type { SelectorFilter } from '~/types/interfaces/SelectorFilter';

/**
 * This function builds the url for the selector with the appropiate filters set.
 * @param filterKey string for a single filter or an array of multiple filters to be set on the selector url
 */
export const buildSelectorUrl = (filters?: SelectorFilter) => {
    const config = useRuntimeConfig();
    const country = useCurrentCountry();

    let filterParams = '';

    if (filters && Object.keys(filters)) {
        Object.keys(filters).forEach((key) => {
            filterParams += `&${key}=${filters[key as keyof SelectorFilter]}`;
        });
    }

    return `${
        config.public.env == 'development'
            ? 'https://konfigurator-staging.insta-drive.com'
            : 'https://konfigurator.insta-drive.com'
    }/?cc=${country.value}${filterParams}${config.public.siteMode === 'B2B' ? '&business=1' : ''}`;
};
